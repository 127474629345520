@import-normalize;

*,
*:before,
*:after {
  box-sizing: border-box;
}

.title-blue {
  color: #004AAC !important;
}

.title-white {
  color: #fff !important;
}

.title-grey {
  color: #707070 !important;
}

h2.ant-typography,
.ant-typography h2 {
  font-size: 30px;
  font-weight: bold;
}

/*background di tutto il sito*/
.bg-gradient {
  background: linear-gradient(to top, #62AAF5 30%, #6178F8 90%) !important;
}
.bg-gradient-g {
  background: linear-gradient(to top, #6178F8 30%, #064EAD 90%) !important;
}
/*contenitore immagini pagina non trovata, loading*/
.container-image-responsive {
  max-width: 800px;
  height: auto;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid rgba(42, 49, 115, 0.54);
  border-radius: 32px;
  background-color: white;
}

.image-responsive {
  width: 100%;
  height: auto;
}

/*centrare verticalmente e orizzontalmente*/
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Logo*/
.logo-header {
  height: 60px;
  width: auto;
  margin: 15px 5px;
}

.logo-header-company {
  height: 100px;
  width: auto;
  margin: 0px;
}

.ant-form-item {
  margin-bottom: 10px;
}

.form-password .ant-form-item-explain.ant-form-item-explain-error {
  color: #000000;
}

/*Caratteristihe card*/
.card-style {
  width: 65%;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
  height: 100%;
}

@media screen and (max-width: 1600px) {
  .card-style {
    width: 65% !important;
  }
}

@media screen and (max-width: 1280px) {
  .card-style {
    width: 85% !important;
  }
}

@media screen and (max-width: 950px) {
  .card-style {
    width: 95% !important;
  }
}

@media screen and (max-width: 767px) {
  .card-style {
    height: auto;
  }
}

/*ombra*/
.shadow-button {
  box-shadow: 0px 5px 10px 0px #4545454d !important;
  margin-bottom: 24px;
}

.shadow-button:disabled {
  background-color: #c7c7c7 !important;
}

.container {
  display: grid;
  padding: 80px 0;
  width: 100%;
  height: 100%;
}

.verify_code_input input {
  margin-right: 5px !important;
  border-radius: 6px !important;
  font-family: "Work sans" !important;
  font-size: 30px !important;
  width: 38px !important;
  height: 44px !important;
}

/*card video*/
.card_video {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card_video:hover {
  background-color: #f5f5f5;
}

/*icone delle varie tipologie di video*/
.icon_card {
  width: 50%;
  height: 50%;
  margin: 10px;
}

.paragraph_form {
  font-size: 16px;
  color: #004AAC;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.paragraph_form_grey {
  font-size: 16px;
  color: #707070;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.container-style {
  background-color: #fff;
  border-radius: 16px;
  margin: 10px;
}

/*carosello*/
.width_carousel {
  width: 560px;
  padding: 20px 0px;
}

@media screen and (max-width: 1570px) {
  .width_carousel {
    width: 600px;
  }
}

@media screen and (max-width: 1400px) {
  .width_carousel {
    width: 550px;
  }
}

@media screen and (max-width: 1250px) {
  .width_carousel {
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .width_carousel {
    width: 340px;
    height: 500px;
  }
}

.slick-dots {
  margin-bottom: 20px;
  position: initial;
}

/*dot blue per carosello in welcome*/
.dot-blue .slick-dots li button:before {
  font-size: 20px;
  margin-top: 20px;
}

.dot-blue .slick-dots li button:before {
  color: #004AAC;
}

.dot-blue .slick-dots li.slick-active button:before {
  color: #004AAC;
}

/*dot white per carosello in login*/
.dot-white .slick-dots li button:before {
  font-size: 20px;
  color: #ffffffcc;
}

.dot-white .slick-dots li.slick-active button:before {
  color: #ffffffcc;
}

/*Navbar menu*/
.menuBar {
  padding: 0 15px;
  border-bottom: solid 1px #e8e8e8;
  box-shadow: 0 0 30px #f3f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*
  */
}

.responsive-menu {
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 30px;
  align-items: center;
  margin: 0px 20px;
}

@media screen and (max-width: 992px) {
  .responsive-menu {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 30px 20px;
  }
}

.logo {
  width: 150px;
  margin-right: auto;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  padding: 0px 20px 0px 0px;
  text-transform: capitalize;
}

/* override ant-menu styles */
.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 0px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

.menu-item {
  color: #707070 !important;
  font-size: 18px;
  font-weight: bold;
}

.menu-item:hover {
  color: #004AAC !important;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
  color: #004AAC !important;
}

.menuCon .ant-menu-item {
  height: 50px !important;
}

/*welcomepage*/
.container-img {
  background-image: url("./images/logo_kairos_blu.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  height: 25%;
  justify-content: center;
  color: #004AAC;
  margin: 30px 0px;
}

.container-p-welcome {
  color: #004AAC;
  margin-bottom: 30px;
  line-height: 1.3;
}

/*bottone in welcome page che porta al questionario*/
.btn-test {
  background-color: #004AAC;
  border: 4px solid #004AAC;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  padding: 25px 60px !important;
  color: #ffffff;
}

.btn-test:hover {
  background-color: #004AAC !important;
  color: #ffffff !important;
  border: 4px solid #004AAC;
}

/*pagina questionario, barra voti e barra punteggio*/
.no-border-progress .ant-progress-inner,
.ant-progress-success-bg,
.ant-progress-bg {
  border-radius: 0%;
  vertical-align: super;
}

.slide-slider .ant-slider-rail {
  height: 20px;
  background-color: #cbe1ff;
  border: 1px solid #cbe1ff;
}

.slide-slider .ant-slider-track {
  height: 20px !important;
}

.slide-slider .ant-slider-step {
  height: 20px !important;
}

.slide-slider .ant-slider-dot {
  width: 20px;
  height: 20px;
  top: 0px !important;
  transform: translateX(-30%);
  border: 2px solid #579cad;
}

.slide-slider .ant-slider-handle {
  margin-top: 0px;
  width: 20px !important;
  height: 20px !important;
  color: #004AAC;
}

.slide-slider .ant-slider-mark {
  top: 30px;
}

/* singola carda ebook*/
.ebook {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 150px;
  background-color: #fff;
  border-radius: 16px;
  margin: 10px;
}

.ebook:hover {
  transform: scale(1.05);
}

/*collapse in i miei dati e profilo*/
.collapse-style {
  width: 60%;
  border: 0px solid;
  background-color: transparent;
  margin: 10px 0px;
}

@media screen and (max-width: 780px) {
  .collapse-style {
    width: 85% !important;
  }
}

@media screen and (max-width: 550px) {
  .collapse-style {
    width: 95% !important;
  }
}

.collapse-style .ant-collapse-content {
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px !important;
}

.collapse-style-white .ant-collapse-content {
  background-color: #fff;
  border-radius: 0px 0px 20px 20px !important;
}

.collapse-panel {
  background-color: #004AAC;
}

.title-panel {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 0px;
  font-weight: bold;
  color: #fff;
}

/*colonna blu e lightblue login, signup ecc*/
.col-blue {
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #62AAF5 0%, #6178F8 100%) 0% 0% no-repeat;
  border-radius: 20px 0px 0px 20px;
}

.col-lightblue {
  background-color: #ffffff;
  padding: 20px 0px;
  border-radius: 0px 20px 20px 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .col-blue {
    border-radius: 20px 20px 0px 0px;
  }

  .col-lightblue {
    border-radius: 0px 0px 20px 20px;
  }
}

/*box messaggio video corsi presenti, profilo ecc*/
.message {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px 15px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

/*contenitore titolo EbookDetail*/
.container-title-book {
  text-align: center;
  background-color: #004AAC;
  color: #fff;
  padding: 20px 0px 10px;
  border-radius: 20px 20px 0px 0px;
}

/*box contenitore risultato/punteggio questionario*/
.container-result-survey {
  text-align: center;
  background-color: #004AAC;
  padding: 20px 0px 10px;
  border-radius: 20px 20px 0px 0px;
}

/*contenitore profilo*/
.container-profile {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.border-container {
  border: 2px solid #004AAC;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.menu-flex {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1200px) {
  .menu-flex {
    display: inline;
  }
}

.img_mock {
  width: auto;
  height: auto;
}

@media screen and (max-width: 1200px) {
  .img_mock {
    width: 500px;
    height: 500px;
  }
}

@media screen and (max-width: 500px) {
  .img_mock {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 375px) {
  .img_mock {
    width: 330px;
    height: 330px;
  }
}

.bg_img {
  background-image: url("./images/handshake.jpg");
  background-repeat: no-repeat;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}

/*bottone caricamento logo iscrizione scuole aziende*/
.btn-upload-image {
  background-color: transparent;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
}

.btn-upload-image:hover {
  background-color: #004AAC !important;
  color: #ffffff !important;
  border: 3px solid #004AAC;
}

/*antd upload image */
.ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
}

.ant-upload-list-picture-card-container {
  width: 100%;
  height: 100%;
}

.ant-upload-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px;
  border: 0px;
}

.ant-upload.ant-upload-select-picture-card {
  height: 100%;
}

/*form error antd*/
.ant-form-item-explain.ant-form-item-explain-error {
  color: #860303;
  font-weight: bold;
  font-size: 12px;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  border-color: transparent !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent !important;
}

.ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.menuCon {
  display: flex;
}

@media screen and (max-width: 1200px) {
  .menuCon {
    display: inline;
  }
}

.submenu {
  font-size: 18px;
  color: #004AAC;
  font-weight: bold;
  margin: 0px 40px;
  cursor: pointer;
}

.submenu-selected {
  font-size: 18px;
  color: #004AAC;
  border-bottom: 2px solid #004AAC;
  font-weight: bold;
  margin: 0px 40px;
  cursor: pointer;
}

.date-form {
  color: #004AAC;
  font-size: 24px;
}

/* pagina home*/
.title_home {
  font-size: 32px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 34px !important;
}

.title-blu-home {
  font-size: 26px;
  color: #004AAC;
  font-weight: 500;
  margin-bottom: 10px;
}
.title-grey-home {
  font-size: 26px;
  color: #707070;
  font-weight: 500;
  margin-bottom: 10px;
}